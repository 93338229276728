const keywords = [
    `blog`,
    `software`,
    `javascript`,
    `ux`,
    `dx`,
    `product development`,
    `tutorials`,
    `learning`,
    `teaching`,
];

export { keywords }